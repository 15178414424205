.paper-detail-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: #c4c4c4;
    padding: 0px 20px 20px 20px;
  }
  
  .paper-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    justify-content: space-between;
  }
  
  .paper-info {
    width: 50%;
    padding: 20px;
    overflow-y: auto;
  }
  
  .paper-pdf {
    width: 50%;
    height: 100%;
  }
  
  
  .authors {
    font-style: italic;
    margin-bottom: 20px;
    color: #666;
  }
  
  .paper-detail-container h2 {
    font-size: 20px;
    color: #4CAF50;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .abstract {
    text-align: justify;
  }
  
  iframe {
    border: none;
  }
  
  @media (max-width: 768px) {
    .paper-content {
      flex-direction: column;
    }
  
    .paper-info,
    .paper-pdf {
      width: 100%;
    }
  
    .paper-pdf {
      height: 500px;
      border-left: none;
      border-top: 1px solid #ccc;
    }
  }
  
  .summary {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .summary h2 {
    color: #4CAF50;
    margin-bottom: 15px;
  }
  
  .summary p {
    margin-bottom: 15px;
  }
  
  .summary ol {
    padding-left: 20px;
    list-style-type: none;
    counter-reset: item;
  }
  
  .summary ol > li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
  }
  
  .summary ol > li:before {
    content: counter(item) ".";
    counter-increment: item;
    color: #4CAF50;
    position: absolute;
    left: 0;
    font-weight: bold;
  }
  
  .summary ul {
    padding-left: 20px;
    margin-top: 5px;
    list-style-type: none;
  }
  
  .summary ul li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 15px;
  }
  
  .summary ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #4CAF50;
  }
  
  .graph-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  @media (max-width: 600px) {
    h1 {
      font-size: 20px;
    }
  
    h2 {
      font-size: 18px;
    }
  
    .paper-info {
      padding: 10px;
    }
  
    .summary ol,
    .summary ul {
      padding-left: 10px;
    }
  
    .summary ol > li {
      padding-left: 20px;
    }
  
    .summary ul li {
      padding-left: 12px;
    }
  }