.upload-container {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  
  .file-upload-input {
    display: none; /* Hide the default input */
  }
  
  .file-upload-label {
    padding: 10px 15px;
    background-color: transparent;
    border: none;
    color: #282828;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;

  }


  
  .file-upload-label:hover {
    background-color: #191919;
  }
  

  .upload-subtext {
    margin-top: 8px;
    font-size: 0.8em;
    color: #666666;
    text-align: center;
    margin-left: 10px;
  }

  .upload-popup {
    position: absolute;
    top: 7%;
    right: 115px;
    z-index: 10;
    width: 300px; /* Adjust based on your design */
    padding: 20px 10px;
    background-color: #2323234a;
    color: #fcfbf2; /* Light color for text */
    border: 0.5px solid #3c3c3c;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow */
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .upload-popup input[type="file"], .upload-popup input[type="text"], .upload-popup button {
    width: calc(100% - 40px); /* Full width minus padding */
    padding: 15px 10px;
    margin-top: 10px;
    box-sizing: border-box;
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
    color: #fcfbf2; /* Light color for text */
    border-radius: 8px;
    letter-spacing: 0.2px;
    line-height: 1.3;
    backdrop-filter: blur(10px); /* Apply blur effect */
  
  }
  
  .upload-popup input[type="file"] input[type="text"] {
    margin-bottom: 20px; /* Space between inputs and button */
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
  }
  
  .upload-popup input[type="file"]:focus {
    outline: none; /* Optional: Removes the default focus outline */
  }
  
  .upload-popup button {
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #2a2a2a60;
    border: 0.5px solid #7d5aa0;
    color: #7d5aa0;
  
  }
  
  .upload-popup button:hover {
    background-color: #191919; /* Darker on hover */
  }

