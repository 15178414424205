.legend {
    position: absolute;
    top: 0px; /* Adjust as needed */
    right: 0px; /* Adjust for right-side floating */
    padding: 10px;
    background-color: transparent;
    border-radius: 5px;
    display: flex;
    margin: 10px;
    color: #d5d5d5; /* Black text color */
    display: flex;
    flex-direction: column;
}
  
  .legend-item {
    display: flex;
    align-items: center;
  }
  
  .legend-item span {
    margin-left: 5px;
  }

  .graph-button {
    padding: 5px 10px;
    background-color: #2a2a2a60;
    color: #fcfbf2;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 0.5px solid #3c3c3c;
    width: 100%;
    font-family: "Quicksand", sans-serif;
    letter-spacing: 0.2px;
    line-height: 1.3;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-bottom: 10px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch-label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #4f4f4f93;
    border: 0.5px solid #7a7a7a;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 24px;
  }
  
  .switch-label:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: #f6e9ff;
    -webkit-transition: .4s;
    transition: .2s;
    border-radius: 50%;
  }
  
  input:checked + .switch-label {
    background-color: #b45bf0;
  }
  
  input:checked + .switch-label:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Optional: CSS for the switch text */
  .switch-inner {
    /* Style for the text inside the switch */
    color: #fff;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 75%;
    font-size: 12px;
  }
  
  input:checked + .switch-label .switch-inner {
    left: 25%;
  }