.file-container {
    padding: 20px;
    padding-top: 10px;
    width: 50%;
    height: auto;
    margin: 20px;
    background-color: rgba(0, 128, 0, 0);
  }
  
  .brains-header {
    display: flex;
    justify-content: space-between; /* Aligns children to each side */
    align-items: center; /* Centers items vertically */
    padding: 0 10px; /* Add padding as needed */
    color: #ffffff;
  
  }
  
  .card-header {
    display: flex;
    justify-content: space-between; /* Aligns children to each side */
    align-items: center; /* Centers items vertically */
    color: #ffffff;
  
  }
  
  
  .plus-icon {
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
  }
  
  .plus-icon:hover {
  background-color: #2e2e2e60;
  }
  
  .play-icon {
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
    color: #9a7bc9bd;
  }
  
  .play-icon:hover {
    background-color: #0f0f0f;
    border: 0.5px solid #6a597a; /* Slight border */
  
    }
  
  
  
  .file-store-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background: #19191960;
    border-radius: 0px;
    max-height: 79vh;
    overflow-y: auto;

  }
  
  .card {
    padding: 15px;
    padding-right: 0px;
    color: #000000; /* Off-white text color */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    border: 0.5px solid #313131; /* Slight border */
    font-family: "Quicksand", sans-serif; /* Font family */
    letter-spacing: 0.2px; /* Letter spacing */
    line-height: 1.3; /* Line height */
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Stack children vertically */
    align-items: left; /* Center children horizontally */
    text-align: left; /* Center text */
    justify-content: space-between;
    width: auto; /* Adjust width as needed, or keep it auto */
    background: transparent;
    border: 0.5px solid #3c3c3c;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
 
  
  }

  .file-card {
    padding: 15px;
    padding-right: 0px;
    color: #000000; /* Off-white text color */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    border: 0.5px solid #313131; /* Slight border */
    font-family: "Quicksand", sans-serif; /* Font family */
    letter-spacing: 0.2px; /* Letter spacing */
    line-height: 1.3; /* Line height */
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Stack children vertically */
    align-items: left; /* Center children horizontally */
    text-align: left; /* Center text */
    justify-content: space-between;
    width: auto; /* Adjust width as needed, or keep it auto */
    background: transparent;
    border: none;
    border-bottom: 0.5px solid #2f2f2f;
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
    max-width: 100%; /* Adjust this value as needed */
    overflow-wrap: break-word;
    word-wrap: break-word; /* Older browsers */
    white-space: normal; /* Ensures that the whitespace inside the element is handled normally */
    overflow: hidden; /* Hides any overflow not handled by break-word */
    text-overflow: ellipsis;
  
  }
  
  .card h3 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card p {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .card-chips {
    display: flex;
    justify-content: center;
    gap: 10px;
    transition: opacity 0.3s ease;
    width: 100%;
  }
  
  .chip {
    padding: 5px 10px;
    background-color: transparent;
    border: none; /* Slight border */
    display: flex;
    align-items: center;
    justify-content: center;
  
    color: #ffffff;
    border-radius: 15px; /* Rounded edges for chip */
    font-size: 0.8em; /* Smaller font size */
    cursor: default; /* Default cursor to indicate non-clickable */
  }
  
  .popup-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .popup {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    width: 300px; /* Adjust based on your design */
    background-color: #2323234a;
    color: #fcfbf2; /* Light color for text */
    border-radius: 8px;
    border: 0.5px solid #3c3c3c; /* Light border */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow */
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  
  .popup button{
    margin-bottom: 10px;
  }
  
  .popup input[type="text"], .popup button {
    width: calc(100% - 40px); /* Full width minus padding */
    padding: 15px 10px;
    margin-top: 10px;
    box-sizing: border-box;
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
    color: #fcfbf2; /* Light color for text */
    border-radius: 8px;
    letter-spacing: 0.2px;
    line-height: 1.3;
    backdrop-filter: blur(10px); /* Apply blur effect */
  
  }
  
  .popup input[type="text"] {
    margin-bottom: 20px; /* Space between inputs and button */
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
  }
  
  .popup input[type="text"]:focus {
    outline: none; /* Optional: Removes the default focus outline */
  }
  
  .popup button {
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #2a2a2a60;
    border: 0.5px solid #7d5aa0;
    color: #7d5aa0;
  
  }
  
  .popup button:hover {
    background-color: #191919; /* Darker on hover */
  }
  
  
  .modal-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .search-modal-input {
    margin: 10px 0;
    background-color: transparent;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    width: 100% !important;
    color: #fcfbf2;
  }

  .search-modal-input:focus {
    outline: none;
  }

  .search-input-group {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    gap: 10px; /* Optional: adds some space between the input and button */
    background-color: transparent;
    border: 0.5px solid #2f2f2f;
    padding: 0px;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .modal-input-large {
    margin: 10px 0;
    background-color: transparent;
    padding: 10px;
    font-size: 16px;
    border: 0.5px solid #3c3c3c;
    border-radius: 8px;
    width: auto !important;
    height: 100px !important;
    color: #fcfbf2;
  }
  
  .search-modal-button {
    background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);
    border: 1px solid #000;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
    margin-right: 10px;
  }
  
  .modal-button:active .modal-input:active #Modal {
    border: 1px solid #7d5aa0;
  }
  
  
  .modal-checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
  
  .modal-checkbox input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    outline: none;
  }
  
  .modal-checkbox input[type="checkbox"]:checked {
    background-color: #6a3c97;
    border: none;
  }
  
  .modal-checkbox input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 10px;
    border: solid #232323a6;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .modal-checkbox label {
    display: flex;
    align-items: center;
    color: #fcfbf2;
    font-size: 16px;
  }
  
  .modal-checkbox .helper-text {
    margin-top: 5px;
    font-size: 12px;
    color: #ccc;
  }
  
  .brains-table {
    width: 100%;
    border-collapse: collapse;
    color: #fcfbf2;
  }
  
  .brains-table th, .brains-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .brains-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .brains-table td[contenteditable="true"] {
    background-color: #fff;
    cursor: text;
  }
  

  .paper-card {
    border: 0.5px solid #3c3c3c; /* Light border */
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .paper-card button{
    background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);
    border: 1px solid #000;
    border-radius: 12px;
    color: white;
    margin-top: 10px;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
  }

  .paper-card a {
    text-decoration: none;
    color: #7d5aa0;
  }
  
  .paper-info {
    margin-top: 10px;
  }
  
  .paper-details {
    margin-top: 10px;
    background-color: #141414;
    padding: 10px;
    border-radius: 5px;
  }



.menu-item {
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    z-index: 10010;
}

.menu-item:last-child {
    border-bottom: none;
}

.menu-item:hover {
    background-color: #f0f0f0;
}