.assistant-container {
    display: flex;
    justify-content: left;
    align-items: center;
    color: #fcfbf2;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .assistant-button {
    padding: 10px 15px;
    background-color: transparent;
    color: #fcfbf2;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
  }

  .collapse-type {
    padding: 5px 10px;
    background-color: transparent; /* Example pill background color */
    color: #87877f;
    border-radius: 2px; /* Gives the pill shape */
    border: 1px solid #87877f;
    text-align: center;
    font-size: 0.875rem; /* Adjust as needed */
    white-space: nowrap; /* Prevents the text inside the pill from wrapping */
    cursor: pointer;
  }

  .assistant-content {
    overflow-y: auto;
    flex: 1 1;
    padding: 15px;
    max-width: 100%;
    max-height: 700px;
    border-radius: 12px;
  }

  .assistant-content a {
    color: #934da6;
    text-decoration: underline; /* Optional: adds underline on hover */
  }

  .assistant-button:hover {
    background-color: #191919;
    border: 0.5px solid #282828;    

  }
  
  .research-button {
    padding: 15px 0px;
    background-color: #2a2a2a60;
    color: #fcfbf2;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    border: 0.5px solid #3c3c3c;
    width: 50%;
    font-family: "Quicksand", sans-serif;
    letter-spacing: 0.2px;
    line-height: 1.3;
  }
  
  .research-button:hover {
    background-color: #2e2e2e;
  }
  
  .assistant-container .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .assistant-container .model-container {
    padding: 10px;
    margin-top: 10px;
    background-color: #2a2a2a60; /* Semi-transparent background similar to the research-button */
    border: 0.5px solid #3c3c3c; /* Consistent border styling */
    border-radius: 8px; /* Rounded corners like the buttons */
    color: #fcfbf2; /* Text color consistent with other elements */
    font-family: "Quicksand", sans-serif; /* Assuming you're using Quicksand across your UI */
    margin-bottom: 20px; /* Space below the container */
    display: flex; /* For alignment of child elements */
    justify-content: space-between; /* Spacing out child elements */
    align-items: center; /* Vertically centering child elements */
  }
  .assistant-container .modal-content {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    max-width: 700px;
  }
  
  /* Input and textarea styles */
  .assistant-container input, textarea {
    width: calc(100% - 16px);
    padding: 15px 15px; /* Increased padding */
    margin-top: 10px;
    box-sizing: border-box;
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
    border-radius: 6px;
    color: #fcfbf2;
    font-family: monospace;
  }

  .assistant-container textarea:focus {
    outline: none; /* Optional: Removes the default focus outline */
  }

  .assistant-container input:focus {
    outline: none; /* Optional: Removes the default focus outline */
  }
  

  textarea {
    height: 100px; /* Adjust height as needed */
  }
  
  /* Label styles */
  .assistant-container label {
    display: flex;
    margin-top: 20px;
    color: #fcfbf2;
  }

  .paths-grid {
    display: grid;
    padding: 10px;
    border-radius: 12px;
    grid-template-columns: repeat(2, 1fr); /* Creates a 2-column grid */
    gap: 10px; /* Adjust the gap between grid items */
    margin-top: 20px; /* Space above the grid */
    background-color: #1c1c1c;
  }
  
  .path-card {
    border: 0.5px solid #3c3c3c;
    padding: 5px; /* Padding inside the card */
    padding-left: 10px;
    border-radius: 5px; /* Optional: rounded corners */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: simple shadow */
    font-family: monospace;
    background-color: #2a2a2a;
    color: #fcfbf2;
  }

  @media (max-width: 768px) {
    .assistant-container label {
        display: flex;
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        color: #fcfbf2;
      }

      .assistant-container h2 {
        padding-left: 10px;
      }

      /* Input and textarea styles */
  .assistant-container input, textarea {
    width: calc(100% - 16px);
    margin-top: 10px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #dcdbd2;
    color: #282828;
    font-family: monospace;
    margin-left: 10px;
    margin-right: 10px;
  }

  .research-button {
    padding: 10px 25px;
    background-color: transparent;
    color: #282828;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    border: 2px solid #efeee5;
    margin-left: 10px;
    margin-right: 10px;
  }

}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

