.connect-sources-container {
    display: flex;
    justify-content: left;
    align-items: center;
    color: #282828;

  }

.connect-sources-button {
    padding: 10px 25px;
    background-color: transparent;
    color: #282828;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
    border: none;

  }
  
  .connect-sources-button:hover{
    background-color: #272727;

  }

  .connect-sources-button:hover .Key {
    transform: rotate(90deg);
    transition: transform 0.2s ease-in-out;
  }

  .Key {
    color: #fcfbf2;
  }

  .connect-sources {
    padding: 10px 15px;
    background-color: transparent;
    border: none;
    color: #282828;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;


  }
  
  .connect-sources:hover{
    background-color: #191919;

  }
  
  /* Continue with the rest of your ConnectSources styles */
  .sources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    padding: 0px;
    margin-top: 40px;
  }
  
  .source-card {
    padding: 10px;
    border: 0.5px solid #363636;
    text-align: center;
    cursor: pointer; /* Optional: Changes the cursor to indicate the card is interactive */
    background-color: #0f0f0f82;
    border-radius: 8px;
  }

  /* Add to your existing styles */
.input-label {
    display: block;
    margin-top: 20px;
  }
  
  .api-key-input {
    margin-top: 10px;
    padding: 8px;
    width: calc(100% - 16px); /* Adjust based on padding */
    box-sizing: border-box; /* Include padding in width calculation */
    background-color: transparent;
    border: 1px solid #dcdbd2;
    color: #fcfbf2;
    font-family: monospace;
  }
  
  .guide-text {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #7b7b7b;
  }

  .sources-button {
    padding: 10px 25px;
    background-color: #282828;
    color: #fcfbf2;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 40px;
    border-radius: 8px;
    border: none
  }
  
  .sources-button:hover {
    background-color: #2a2a2a;
  }

  .connection-chip {
    top: 20px;
    right: 20px;
    padding: 10px;
    background-color: #749e77; /* Green background */
    color: white;
    border-radius: 12px;
    z-index: 1000; /* Ensure it's above other content */
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
}
  