/* Container for the entire component, giving it a document editor feel */
.thought-container {
    padding: 0px;
    margin-top: 0px;
    width: 100%; /* Optimal reading width */
    background-color: transparent;
    border-radius: 8px; /* Soften edges */
    height: 100% !important; /* Adjust based on content */
    position: relative; /* Added to position the create-button absolutely within */
    overflow: hidden;
  }


  .content-wrapper {
    padding: 0px;
}
  
  /* Styles for the textarea to make it expansive and comfortable for editing */
  .textarea {
    flex: 1; /* Allows textarea to expand and fill the container's height */
    padding: 16px;
    margin-bottom: 0px;
    font-size: 16px; /* Comfortable reading size */
    line-height: 1.5; /* Improve readability */
    resize: none; /* Disable resizing to maintain the editor's layout */
    background-color: transparent; /* Slightly off-white for contrast with container */
    color: #333; /* High contrast for readability */
    outline: none; /* Remove focus outline */
    min-height: 500px;
    border: none;
  }
  
  .textarea:focus {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 0.2rem rgba(0,123,255,.25); /* Subtle glow effect */
  }

  .EasyMDEContainer {
    overflow-y: auto;
    margin-top: 20px;
  }
  
  /* Button styling for a modern, interactive look */
  .create-button {
    position: absolute;
    top: 0px;
    right: 60px;
    padding: 10px 25px;
    background-color: transparent;
    color: #fcfbf2;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
    font-size: 16px;
    max-width: 200px;
    max-height: 50px;
    margin-bottom: 20px;
    border: none;
    z-index: 2000 !important;
  }
  
  .create-button:hover {
    background-color: #2f2f2f; /* Darker shade on hover */
    border: none;

  }
  
  .markdown-preview {
    z-index: 1000;
    flex: 1;
    padding: 16px;
    border: none;
    background-color: transparent; /* Light grey background for the preview */
    overflow-y: auto; /* In case of overflow */
    color: #fcfbf2;
  }

  /*SimpleMDE styling*/

/* Remove border from the SimpleMDE editor */
.CodeMirror, .CodeMirror-scroll .CodeMirror-wrap {
  border: none !important; /* Removes border around the editor area */
  background-color: transparent !important; /* Dark background for the editor */
  color: #fcfbf2 !important;
  min-height: 800px;
}

.CodeMirror {
  border: none !important; /* Removes border around the editor area */


}

/* Adjust the cursor color for better visibility */
.CodeMirror-cursor {
  border-left: 2px solid #bbbbbb !important;
}

.CodeMirror-lines {
  padding: 10px !important; /* Optional: Adds padding inside the editor for better text alignment */
}

.editor-toolbar {
  border: none !important; /* Removes border around the toolbar */
}

.simplemde-container .editor-preview-active .editor-preview-full .editor-preview editor-preview-active,
.simplemde-container .CodeMirror,
.simplemde-container .CodeMirror-scroll {
  color: #fcfbf2; /* Light text color for contrast */
}

/* Adjusting the color for headings, links, etc., in preview mode */
.simplemde-container .editor-preview h1,
.simplemde-container .editor-preview h2,
.simplemde-container .editor-preview h3,
.simplemde-container .editor-preview h4,
.simplemde-container .editor-preview h5,
.simplemde-container .editor-preview h6,
.simplemde-container .editor-preview a {
  color: #fcfbf2; /* Light text color for contrast */
}

.simplemde-container .editor-preview p,
.simplemde-container .editor-preview li {
  color: #fcfbf2; /* Slightly dimmer text color for regular text */
}

.EasyMDEContainer {
  height: 100%;
}

.editor-toolbar button {
  color: #b3b3b3;
}

  
.saved-banner {
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: #749e77; /* Green background */
  color: white;
  border-radius: 12px;
  z-index: 1000; /* Ensure it's above other content */
}

.ql-container {
  height: 100% !important; /* Adjust the height as needed */
  width: 100%;
  margin-bottom: 20px;
  color: #fcfbf2;
  
}

.ql-editor {
  height: 90% !important; /* Adjust the height as needed */
  font-size: 16px;
  color: #fcfbf2;
}

.quill {
  height: 100% !important; /* Adjust the height as needed */
  width: 100%;
  margin-bottom: 20px;
  color: #fcfbf2;
  overflow-y: hidden;
}


.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-container {
  border: none !important;
}

.ql-editor a {
  color: #8c5fe7 !important; /* Change this to your desired link color */
  text-decoration: none !important; /* Optional: Add underline to links */
}

.ql-editor a:hover {
  color: #2980b9; /* Change this to your desired hover color */
  text-decoration: underline; /* Optional: Add underline on hover */
}

.ql-editor {
  font-size: 16px; /* Default font size */
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

