
@media (min-width: 1026px) {

  .card-grid {
    display: grid;
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .hero-section, .cards-section, .feature-card, .dynamic-container {
      flex-direction: column;
      align-items: left;
      justify-content: center;
      padding: 10px;
  }

  .homecard-example-pill {
    font-size: 16px !important;
    text-align: left !important;

  }

  @media screen and (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }

  .hero-section, .cards-section {
      padding: 10px;
      margin-top: 2vh;
  }

  .hero-section, .text-primary {
    font-size: 40px !important;
    margin-bottom: 20px !important;
}

.cards-section, .text-primary {
  font-size: 30px !important;
  margin-bottom: 10px !important;
}

  .feature-card {
      width: 100%;
      margin: 5px;
      padding: 10px;
      margin-left: 0px !important;
  }

  .deploy-container {
    display: block !important;
  }

  .deploy-text {
    margin-bottom: 20px !important;
    margin: 10px !important;
    padding: 10px !important;
  }

  .text-section, .cards-section-about {
      max-width: 100%;
      padding: 5px;
  }

  .button-wrapper, .email-input, .card {
      width: 100%;
  }

  .top-nav ul {
      text-align: center;
  }

  .top-nav ul li {
      display: block;
      margin: 10px 0;
  }


  .homecard.small, .homecard.extra-tall, .homecard.large, .homecard.extra-wide, .homecard.tall, .homecard.wide, .homecard.square {
      width: 100%; /* Ensure cards take full width of the column */
      margin: 5px; /* Reduce margin */
      padding: 10px; /* Adjust padding */
      display: flex; /* Ensuring flex layout for internal elements */
      flex-direction: column; /* Stack internal elements vertically */
      margin-bottom: 40px;

      
  }

  .homecard.square {
    width: 50%;
  }

  .homecard h2 {
    font-size: 20px !important;
  }

  .card-grid {
    overflow-x: hidden !important;
  }


  .hero-section .tagline {
    max-width: 100% !important;
  }
}


/* Adjustments to the hero section for alignment and typography */
.home-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    overflow-x: hidden;
    font-family: "Inter", sans-serif;
}


.about-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

.hero-section {
    background-color: transparent; /* Dark background for contrast */
    color: #fcfbf2; /* Light text for readability */
    min-height: 95vh !important;
    font-family: "Inter", sans-serif;
  }

  .main-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    max-width: 800px;
  }
  
  .report-types {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .report-type {
    font-size: 1rem;
    color: #4CAF50;
    background-color: rgba(76, 175, 80, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 20px;
  }

  .company-logos-section {
    padding-top: 30px;
    background-image: url('/public/dot.png');
    background-repeat: repeat;
    background-size: 3px 3px;
  }

  .company-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
  }
  
  .company-logo {
    margin: 0 15px;
    height: 50px; /* Adjust the height as needed */
  }

  .welcome-section {
    background-color: transparent; /* Dark background for contrast */
    padding: 1.25vw 5vw; /* Use viewport width for responsive padding */
    color: #333333; /* Light text for readability */
    letter-spacing: 0.2px;
    line-height: 1.5;
  }

  .text-section {
    background-color: transparent; /* Dark background for contrast */
    margin: 50px 100px; /* Ample padding for a spacious layout */
    padding-right: 0;
    background: linear-gradient(135deg, #da9a22, #9a7bc9); /* Gradient from dark gray to light purple */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Use transparent text fill color */
    line-height: 1.5;
    letter-spacing: 0.2px;
    max-width: 50%;
  }


  .card-sections-container {
    display: flex;
    justify-content: space-between; /* Adjusts spacing between the sections */
    gap: 20px; /* Optional: Adds space between the sections */
    padding: 20px 0px; /* Optional: Adds padding around the container */
    width: 100%;
}
  

  .cards-section {
    padding: 0 5vw; /* Use viewport width for responsive padding */
    display: flex; /* Use flexbox for layout */
    align-items: left; /* Align items vertically */
    justify-content: space-between; /* Distribute space between items */
    flex-direction: column;
    height: auto;
    padding-top: 10vh;
    padding-bottom: 0px;
    border-radius: 64px 64px 64px 64px;
    width: 80%;
    background-color: rgba(74, 74, 74, 0.1); /* Semi-transparent background */
    border-radius: 8px; /* Rounded edges */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
    backdrop-filter: blur(10px); /* Blur effect */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px 30px;


  }

  .cards-section-about {
    background-color: transparent; /* Dark background for contrast */
    padding: 0 5vw; /* Use viewport width for responsive padding */
    display: flex; /* Use flexbox for layout */
    align-items: left; /* Align items vertically */
    justify-content: space-between; /* Distribute space between items */
    height: 100vh;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .feature-card {
    width: 100%;
    height: auto; /* Auto height to contain its content properly */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    margin: 10px;
    border-radius: 8px; /* Add rounded corners for a softer look */
    background-color: transparent;
    color: #373737;
    text-align: left;
    border: none;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: flex;
  }



  .feature-card::before {
    width: 100%;
    height: 100%;
  }




  .feature-card:hover {
    border-color: #da9a22;
  }
  

 

  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .dynamic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* Global selection style to test functionality */
  ::selection {
    background: #9a7bc9; /* Highlight color */
  }

  
  .hero-section .content {
    text-align: left; /* Align text to the left */
  }
  
  .hero-section .text-primary {
    font-size: 60px; /* Increase font size for the company name */
    font-weight: 400; /* Decrease font weight for a lighter appearance */
    margin-bottom: 20px;
    margin-top: 0;
    color: #e4fbe4;
  padding: 50px 50px;
  max-width: 40%;

  }

  .cards-section .text-primary {
    font-size: 40px; /* Increase font size for the company name */
    font-weight: 400; /* Decrease font weight for a lighter appearance */
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    color: #fcfbf2;
  }

  .cards-section-about .text-primary {
    font-size: 60px; /* Increase font size for the company name */
    font-weight: 400; /* Decrease font weight for a lighter appearance */
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    color: #fcfbf2;
  }

  .cards-section .primary-shadow {
    font-size: 30px; /* Increase font size for the company name */
    font-weight: 600; /* Decrease font weight for a lighter appearance */
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    color: #bebebe31;
    line-height: 1;

  }

  .cards-section-about .primary-shadow {
    font-size: 50px; /* Increase font size for the company name */
    font-weight: 600; /* Decrease font weight for a lighter appearance */
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    color: #fcfbf21a;
    line-height: 1;

  }

  .cards-section p  {
    font-size: 20px; /* Increase font size for the company name */
    margin-bottom: 0;
    margin-top: 2vh;
    padding: 0;
    color: #d4d4d4;
    letter-spacing: 0.px;
    line-height: 1.5;
  }

  .cards-section-about p  {
    font-size: 20px; /* Increase font size for the company name */
    margin-bottom: 0;
    margin-top: 5vh;
    padding: 0;
    color: #b6b5af;
    letter-spacing: 0.px;
    line-height: 1.5;
  }
  
  .hero-section .tagline {
    font-size: 20px; /* Smaller font size for the tagline */
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    color: #fcfbf2;
    
  }

  .button-wrapper {
    border-radius: 20px; /* Rounded corners */
    padding: 8px; /* Padding around the buttons */
    display: flex; /* Flexbox for layout */
    flex-direction: column;
    justify-content: center; /* Center the buttons horizontally */
    align-items: center; /* Align the buttons vertically */
    margin: 10px; /* Margin around the wrapper */
    max-width: 100%;
}

.secondary-button {
    background-color: #040404c6;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;

}
  
  .start-button {
    background: black;
    border: 1px solid #469037a4;
    border-radius: 0px;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgb(21 193 72 / 89%), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
  }



  
  .start-button:active {
    background: linear-gradient(to bottom, #292929 0%, #4f4f4f 100%);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
  }

  .email-input {
    padding: 15px 15px;
    background-color: transparent;
    border: 0.5px solid #7d5aa0;
    color: #9b76c0;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 40px;
    font-family: "Quicksand", sans-serif;
    letter-spacing: 0.2px;
    line-height: 1.3;
    text-decoration: none;
    display: flex; /* Use Flexbox for layout */
  }

  .email-input:focus {
    outline: none;
  }
  


.highlight {
    background-color: #9a7bc9; /* Yellow background for highlight */
    color: #333; /* Dark text for contrast */
    padding: 0 4px; /* Padding around the highlighted text */
    border-radius: 2px; /* Slightly rounded corners for the highlight */
  }
  
  .welcome-section p {
    text-align: justify; /* Justify the paragraph text for a cleaner look */
    margin: 20px 0; /* Margin around paragraphs for spacing */
  }



  .source-value {
  display: flex;
  align-items: center; /* Align the text vertically */
}


/**------ TOPNAV styling -------*/

.top-nav {
  width: 100%; /* Make the navigation bar take the full width of the viewport */
  display: flex; /* Align the buttons horizontally */
  background-color: transparent; /* Add a background color */
  padding: 10px 0; /* Add some padding */

}

.top-nav ul {
  list-style-type: none; /* Remove the bullet points from the list */
  margin: 0;
  padding: 0;

}

.top-nav ul li {
  display: inline; /* Display the list items inline */
  margin: 0 20px; /* Add some margin between the list items */
}

.top-nav ul li a {
  text-decoration: none; /* Remove the underline from the links */
  color: #333; /* Add a color to the links */
}

.top-nav ul li a:hover {
  text-decoration: none; /* Remove the underline from the links */
  color: #9a7bc9; /* Add a color to the links */
}


.card-grid {
  gap: 20px;
  grid-auto-rows: minmax(100px, auto); /* Ensures that rows can size according to content */
  margin-top: 20px;
}

.homecard {
  position: relative;
  border-radius: 0px; /* Rounded edges */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 50px 30px;
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  color: #fff !important;
  overflow: hidden !important; /* Ensures the pseudo-element does not extend outside the card */
}

.homecard-examples {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: 20px;
}

.homecard-example-pill {
  background: black;
  border: 1px solid black;
  border-radius: 0px;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgb(21 193 72 / 89%), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-appearance: none;
  transition: background 0.3s, box-shadow 0.3s;
  padding: 4px 8px;
  font-size: 0.8em;
}

.homecard img {
  width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
}

.homecard p, .homecard h2 {
  word-wrap: break-word; /* Ensures text does not overflow */
}

.homecard p {
  color: #f2f2f2b6;
  font-family: 'Quicksand', sans-serif;

}


.homecard h2 {
  color: #f2f2f2;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: 'IBM Plex Mono', sans-serif;

}


.homecard-link {
  color: #e0eaff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  margin-top: 10px;
  /* background-color: #0e6b2425;
  padding: 10px 20px;
  border: 1px solid rgba(0, 128, 0, 0.748);
  border-radius: 64px;
  width: fit-content; */
}

.homecard-link:hover {
  color: #ffffff;
}

.homecard.small {
  grid-column: span 1;

}

.homecard.large {
  grid-column: span 2;
  grid-row: span 2; /* Makes the card square */

}

.homecard.wide {
  grid-column: span 4; /* Spans full width */
}

.homecard.extra-wide {
  grid-column: span 5;
}

.homecard.tall {
  grid-row: span 2;
}


.homecard.square {
  grid-column: span 2;
  grid-row: span 2; /* Makes the card square */
  width: 50%;
}

.deploy-container {
  display: flex;
  align-items: flex-start; /* Align items at the start of the flex container */
  justify-content: space-between; /* Space between the text and the grid */
  gap: 20px; /* Optional: adds space between the text and the grid */
}

.deploy-text {
  flex: 1; /* Takes up 1 part of the flex container */
  padding-right: 20px; /* Optional: adds some padding between the text and the cards */
}

.footer {
  padding: 20px;
  background-color: transparent;
  color: #fff;
  text-align: center;
  width: 100%;
}

.footer a {
  color: rgb(252, 252, 252);
  text-decoration: none;
  padding: 0 5px;
}

.footer a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}



