body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background-image: url('/public/dot.png');
    background-repeat: repeat;
    background-size: 3px 3px;
    overflow-y: auto !important;
  }
  
  .product-container {
    color: #ececec;

  }
  
  .overview-section {
    text-align: left;
    margin-bottom: 40px;
    margin-left: 20%;
    margin-right: 20%;
  }
  
  .product-list {
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
  }
  
  .product-item {
    margin: 20px 0;
    padding: 10px;
    padding-right: 0px;
    border-bottom: 0.3px solid #4CAF50;
    border-radius: 8px 0px 0px 0px;
    cursor: pointer;
    font-size: 16px;
  }

  .product-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .counter-controls {
    display: flex;
    align-items: center;
  }
  
  .product-item h2 {
    color: #ececec;
    margin: 0;
    font-size: 16px;
  }
  
  .product-details {
    margin-top: 10px;
    color: #ececec;
  }
  
  .product-section {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .product-section h2 {
    color: #ececec;
  }
  
