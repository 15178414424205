.popup-menu {
    position: absolute;
    top: 50px;
    right: 10px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px 15px;
    background-color: transparent;
    border: none;
    color: #282828;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
    background-color: #2323234a;
    color: #fcfbf2; /* Light color for text */
    border-radius: 8px;
    border: 0.5px solid #3c3c3c; /* Light border */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow */
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-popup-menu-button {
    background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);
    border: 1px solid #000;
    border-radius: 12px;
    color: white;
    font-size: 12px;
    padding: 10px 20px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
  }