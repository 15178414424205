.button {
    padding: 10px 15px;
    background-color: transparent;
    color: #282828;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: monospace;
    /*box-shadow: 0 8px 16px rgba(0,0,0,0.2);*/
    margin-right: 0px;
    border: none;
    gap:10px;
    border-radius: 8px;


  }
  
  .button:hover {
    background-color: #191919;
  }