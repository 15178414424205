.show-component {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensure children fill the width */
    margin-bottom: 20px;
    background-color: transparent;
    padding-bottom: 20px;
    border-radius: 0px; /* Soften edges */
    min-height: 85vh; /* Minimum height for a full editor look */
    max-height: 100vh; /* Minimum height for a full editor look */
    min-width: 600px;
    color: #fcfbf2;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
      /* Hide scrollbar for Webkit browsers like Chrome, Safari */
    ::-webkit-scrollbar {
      display: none;
  }

  .content-area {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px 0px; /* Add some padding to prevent content from being hidden behind the input area */
  }
  
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  }

  .button-group {
    display: flex; /* Align buttons horizontally */
    justify-content: left; /* Center buttons in the container */
    margin-top: 10px; /* Space between button group and the next element */
    margin-bottom: 10px;
    overflow-x: auto;

  }

  .button-group button {
    color: #999897; /* Light text color for contrast */
    margin: 0 5px; /* Space between buttons */
    cursor: pointer; /* Change cursor to pointer on hover */
    padding: 10px 25px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
  }
  
  .button-group button:hover {
    background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);
    color: rgb(182, 177, 163); /* Ensure text is still readable on active button */
    border-radius: 8px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    
  }
  
  .button-group button.active {
    background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);
    border: 1px solid #000;
    border-radius: 8px;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
  }
  
  .text-input {
    flex: 1; /* Allows input to expand and fill the container's height */
    padding: 16px;
    padding-left: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0; /* Adjusted margin for consistency */
    margin-bottom: 0px;
    font-size: 16px; /* Comfortable reading size */
    line-height: 1.5; /* Improve readability */
    max-height: 25px;
    border-radius: 0px; /* Consistency with container border-radius */
    background-color: transparent; /* Slightly off-white for contrast with container */
    color: #fcfbf2;
    letter-spacing: 0.2px;
    outline: none; /* Remove focus outline */
    border: none;
  }
  
  .text-input:focus {
    border-color: transparent; /* Highlight focus with a brighter border */
    box-shadow: none; /* Subtle glow effect */
  }
  
  .action-button {
    padding: 10px 25px;
    background-color: transparent;
    border: none;
    color: #fcfbf2;
    border-radius: 32px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
  }
  
  .action-button:hover {
    background-color: #353535; /* Darker shade on hover */
  }

  .icon {
    color: #282828;
  }
  
  .response-container {
    margin-top: 0px;
    padding: 10px;
    color: #575757;
    border-radius: 4px;
    background-color: transparent; /* Light background for contrast */
    width: 100%; /* Ensure it fills its container */
    box-sizing: border-box; /* Include padding in the element's total width */
    height: auto;
  }

  .counts-container {
    display: flex;
    padding: 10px;
    padding-bottom: 5px;
    align-items: center;
    justify-content: space-between;
    gap: 10px; /* Adjust the space between the total count and the pills */
    margin-top: 5px;
  }

  .counts-pills {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px; /* Adjust the space between the total count and the pills */
  }
  
  .document-count {
    margin-right: 20px; /* Adds some space between the document count and the type counts */
    color: #575757
  }
  
  .document-type-pill {
    padding: 5px 10px;
    background-color: transparent; /* Example pill background color */
    color: #87877f;
    border-radius: 2px; /* Gives the pill shape */
    border: 1px solid #87877f;
    text-align: center;
    font-size: 0.875rem; /* Adjust as needed */
    white-space: nowrap; /* Prevents the text inside the pill from wrapping */
    cursor: pointer;
  }

  .input-group {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    gap: 10px; /* Optional: adds some space between the input and button */
    background-color: transparent;
    border: 0.5px solid #2f2f2f;
    padding: 0px;
    border-radius: 8px;
  }

  .input-area {
    position: sticky;
    bottom: 0;
    padding: 10px 0px;
  }

  .input-group-follow-up {
    background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);  border-radius: 8px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    gap: 10px; /* Optional: adds some space between the input and button */
    border: 0.5px solid #2f2f2f;
    padding: 0px;
    border-radius: 8px;
    margin-top: 20px;
  }

  .documents-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 20px; /* Adjust the gap between cards */
}

/* Optional: Adjustments for smaller screens */
@media (max-width: 768px) {
  .documents-grid .document-card {
      padding: 10px;
      font-size: 90%;
      max-height: 300px;
  }

  .view-toggle {
    visibility: hidden;
  }
}

.view-toggle {
  text-align: left;
  margin-top: 10px; /* Adjust spacing as needed */
}

.view-toggle button {
  background-color: transparent; /* Make buttons transparent */
  border: none; /* Remove borders */
  color: #2f2f2f; /* Light text color for contrast */
  font-size: 12px; /* Adjust font size as needed */
  padding: 5px 10px; /* Minimal padding */
  cursor: pointer; /* Cursor to indicate clickable */
  transition: color 0.3s; /* Smooth transition for hover effect */
}


.view-toggle button.active {
  color: #898784; /* Even darker for the active view mode */
}

.data-card {
  padding: 20px;
  background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
  text-align: left;
  color: #f2f1e6;
  margin-top: 20px;

}

.no-data-card {
  padding: 20px;
  background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);  border-radius: 8px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  text-align: left;
  color: #f2f1e6;
  margin-top: 20px;

}

.get-started-list {
  text-align: left;
  padding-left: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
  list-style-type: none; /* Removes the default list numbering */
  padding-left: 0;
}

.get-started-list li {
  margin-bottom: 10px;
  background-color: #2a2a2a27;
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  line-height: 1.5;
  border: 0.5px solid #3c3c3c;

}

.document-type-pill.selected {
  background-color: #353935; /* Example: Blue background */
  color: #f1efe4; /* White text */
  border-radius: 2px; /* Optional: Rounded corners */
}

.square-widget {
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.square-widget h3 {
  margin: 0;
  padding: 10px;
  background-color: transparent;
  border: 1px solid green;
}

.widget-content {
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  width: 100% !important;
}

.widget-content::-webkit-scrollbar {
  width: 6px;
}

.widget-content::-webkit-scrollbar-track {
  background: transparent;
}

.widget-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.widget-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.table-widget-container {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.table-widget-container table {
  min-width: 650px;
}

.table-header-cell {
  background-color: #f5f5f5;
  font-weight: bold;
  white-space: nowrap;
}

.table-body-cell {
  white-space: nowrap;
}

/* Custom scrollbar styles for webkit browsers */
.table-widget-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-widget-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-widget-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.table-widget-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}



  