.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to ensure it's on top */
  }
  
  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2323234a;
    padding: 20px;
    padding-top: 20px;
    border-radius: 5px;
    max-width: 500px;
    min-width: 600px;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    border-radius: 8px;
    border: 0.5px solid #323232;
    color: #fcfbf2;
  }

  .modal-content label {
    max-width: 600px;
  }
  
  .modal-close {
    float: right;
    border: none;
    background: none;
    font-size: 1.5rem;
    color: #c7c7c7;
    z-index: 2000 !important;
  }

  .modal-developer-text {
    font-size: 0.8rem; /* Smaller font size */
    margin-top: 40px; /* Space from the content above */
    text-align: left; /* Center-align the text */
    color: #484848;
  }

  /* Media query for phone screens */
@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: 100%;
    max-width: 100% !important; /* Ensures the modal does not exceed the screen width */
    min-width: 100% !important;
    max-height: 100%; /* Ensures the modal does not exceed the screen height */
    min-height: 100% !important;
    margin: 0px;
    margin-top: 50px;
    border-radius: 0; /* Optional: removes border radius for full screen modal */
    overflow-y: auto;
  }

  .modal-content .create-button {
    position: fixed;
    bottom: 90px;
    right: 60px;
    padding: 10px 25px;
    background-color: transparent;
    color: #fcfbf2;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
    font-size: 16px;
    max-width: 200px;
    max-height: 50px;
    margin-bottom: 20px;
    border: 1px solid #616161;
  }

  .modal-content .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

