.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #040404;
    background: radial-gradient(circle, #040404, #1f1f1f);

  }
  
  .signup-input {
    margin: 10px 0;
    background-color: transparent;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    max-width: 300px;
    color: #fcfbf2;
  }
  
  .signup-button {
    background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);
    border: 1px solid #000;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
    margin-top: 20px;
  }