.paladin-container {
    padding: 10px 0px;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    color: #ffffff;
  }
  
  .paladin-container h2 {
    color: #ffffff;
    border-bottom: 1px solid #3c3c3c;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .paladin-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .form-group label {
    color: #ffffff;
    font-size: 14px;
  }
  
  .form-group input[type="file"],
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
    color: #fcfbf2;
    border-radius: 8px;
    resize: vertical;
    outline: none;

  }
  
  .form-group textarea {
    min-height: 100px;
  }
  
  .form-group input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .submit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #2a2a2a60;
    border: 0.5px solid #469037;
    color: #469037;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
  }
  
  .submit-button:hover {
    background-color: #191919;
  }
  
  .uploaded-file {
    margin-top: 10px;
    padding: 10px;
    background: #19191960;
    border-radius: 8px;
    border: 0.5px solid #3c3c3c;
    outline: none;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    align-items: flex-start;
  }
  
  .checkbox-group label {
    margin-left: 10px;
  }

  .paladin-columns {
    display: flex;
    gap: 2rem;
  }
  
  .paladin-column {
    flex: 1;
  }
  
  .left-column {
    max-width: 40%;
  }
  
  .report-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2px;
  }
  
  .report-card {
    background: black;
    border: 1px solid #000000a4;
    border-radius: 0px;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgb(21 193 72 / 89%), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;

    position: relative;

  }

  .report-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }
  
  .report-card h4 {
    margin-top: 0;
  }

  .status-pill {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0rem;
    text-transform: capitalize;
    width: 70px; /* Set a fixed width */
    text-align: center; /* Center the text */
    display: inline-block; /* Allows width to be set */
  }
  
  .status-pill.upcoming {
    background-color: #ffd90057;
    border: 0.5px solid #ffd700;
    color: #ffd700;
  }
  
  .status-pill.complete {
    background-color: #4caf4f51;
    border: 0.5px solid #4caf50;
    color: #4caf50;
  }
  
  .status-pill.manual {
    background-color: #2195f33c;
    border: 0.5px solid #2196f3;
    color: #2196f3;
  }
  
  .report-card p {
    margin: 0.25rem 0;
  }

  .modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
  }

  .modal h2 {
    color: #000000;
  }
  
  .modal-content {
    background-color: #ffffff;
    color: #000000;
    margin: 0;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 1000px;
    max-height: 90vh;
    overflow: auto;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #dafdde;
  }

  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .checkbox-group div {
    display: flex;
    align-items: center;
  }
  
  .checkbox-group input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #3c3c3c;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
  }
  
  .checkbox-group input[type="checkbox"]:checked {
    background-color: #469037;
    border-color: #469037;
  }
  
  .checkbox-group input[type="checkbox"]:checked::before {
    content: '\2714';
    font-size: 16px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .checkbox-group label {
    margin: 0;
    cursor: pointer;
  }