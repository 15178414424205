.research-query-container {
    padding: 20px;
    padding-top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
  }
  
  .research-query-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: #ffffff;
    border-bottom: 1px solid #469037;
    margin-bottom: 10px;
  }
  
  .research-query-form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .research-query-input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
    color: #fcfbf2;
    border-radius: 8px;
  }
  
  .research-query-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #2a2a2a60;
    border: 0.5px solid #469037;
    color: #469037;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .research-query-button:hover {
    background-color: #191919;
  }
  
  .research-query-loader {
    text-align: center;
    color: #ffffff;
    margin: 20px 0;
  }
  
  .research-query-results {
    background: #19191960;
    padding: 20px;
    border-radius: 8px;
    color: #ffffff;
  }
  
  .research-query-results h2 {
    border-bottom: 1px solid #3c3c3c;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .research-query-results h3 {
    margin-top: 20px;
    color: #7bc97bbd;
  }
  
  .research-query-results ul {
    list-style-type: none;
    padding: 0;
  }
  
  .research-query-results li {
    margin-bottom: 20px;
    padding: 15px;
  }
  
  .research-query-results a {
    color: #469037;
    text-decoration: none;
  }
  
  .research-query-results a:hover {
    text-decoration: underline;
  }

  .research-query-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .input-group-wizard {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .input-group-wizard label {
    color: #ffffff;
    font-size: 14px;
  }
  
  .research-query-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
    color: #fcfbf2;
    border-radius: 8px;
    resize: vertical;
    min-height: 100px;
  }
  

  .tab-container {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tab {

    background: black;
    border: 1px solid #469037a4;
    border-radius: 0px;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(21, 193, 73, 0.505), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
  
    padding: 5px 20px;
    font-size: 16px;
    border: 0.5px solid #3c3c3c;
    color: #fcfbf2;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tab.active {
    border-bottom: 2px solid #469037;
  }
  
  .content-container {
    background: #19191960;
    padding: 20px;
    border-radius: 8px;
  }
  
  .source-tabs {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .source-tab {
    padding: 5px 20px;
    font-size: 14px;
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
    color: #fcfbf2;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .source-tab.active {
    background-color: #3c3c3c;
    border-bottom: 2px solid #469037;
  }

  .project-name-input {
    font-size: 1.5em;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #ccc;
    background: transparent;
    margin: 19.92px 0px;
    width: 100%;
    max-width: 300px;
    color: #ffffff;
    outline: none;
  }
  
  .research-query-header h2 {
    cursor: pointer;
  }

  .advanced-section {
    margin-top: 20px;
    color: white;
  }
  
  .advanced-toggle {
    background-color: #46903749;
    border: 0.5px solid #469037;
    color: #469037;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 0px;
  }
  
  .advanced-content {
    margin-top: 15px;
    padding: 15px;
  }
  
  .input-group-mount {
    display: flex;
    margin-bottom: 10px;
    outline: none;
  }
  
  .input-group-mount .mount-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #2a2a2a60;
    border: 0.5px solid #3c3c3c;
    color: #fcfbf2;
    resize: vertical;
    outline: none;
}
  
  .mount-button {
    background-color: #46903749;
    border: 0.5px solid #469037;
    color: #469037;
    padding: 5px 10px;
    cursor: pointer;
  }

  .remove-button {
    background-color: #a421213d;
    border: 0.5px solid #a42121;
    color: #a42121;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .mounted-sources ul {
    list-style-type: none;
    padding: 0;
  }
  
  .mounted-sources li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px;
    border: 0.5px solid #525252;

  }

  .drag-drop-area {
    border: 1px dashed #469037;
    background-color: #46903731;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .drag-drop-area.drag-active {
    background-color: rgba(125, 90, 160, 0.1);
  }
  
  .drag-drop-area p {
    margin-bottom: 10px;
  }
  
  .drag-drop-area button {
    background-color: #469037;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .uploaded-files ul {
    list-style-type: none;
    padding: 0;
  }
  
  .uploaded-files li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px;
    border: 0.5px solid #525252;
  }
  

  .result-card {
    background: black;
    border: 1px solid #000000a4;
    border-radius: 0px;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgb(21 193 72 / 89%), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;
    position: relative;
    padding-bottom: 40px; /* Make space for the buttons */
  }
  
  .action-buttons {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  
  .approve-button, .decline-button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .approve-button {
    background-color: #46903749;
    border: 0.5px solid #469037;
    color: #469037;
  }
  
  .decline-button {
    background-color: #a421213d;
    border: 0.5px solid #a42121;
    color: #a42121;
  }
