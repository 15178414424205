.brains-container {
  padding: 20px;
  padding-top: 10px;
  width: 50%;
  height: auto;
  max-height: 85vh;
  margin: 20px;
  background-color: rgba(0, 128, 0, 0.081);
  border: 0.5px solid rgba(0, 128, 0, 0.3);
  border-radius: 0px;
  overflow: auto;
}

.brains-header {
  display: flex;
  justify-content: space-between; /* Aligns children to each side */
  align-items: center; /* Centers items vertically */
  padding: 0 10px; /* Add padding as needed */
  color: #ffffff;
  border-bottom: 1px solid #469037;
  margin-bottom: 10px;

}

.card-header {
  display: flex;
  justify-content: space-between; /* Aligns children to each side */
  align-items: center; /* Centers items vertically */
  color: #ffffff;

}


.plus-icon {
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
}

.plus-icon:hover {
background-color: #2e2e2e60;
}

.play-icon {
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  color: #9a7bc9bd;
}

.play-icon:hover {
  background-color: #0f0f0f;
  border: 0.5px solid #6a597a; /* Slight border */

  }



.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  background: transparent;
  padding: 20px 0px 20px 0px;
  border-radius: 8px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  background: #19191960;
  padding: 20px;
  border-radius: 8px;
}

.brain-card {
  background: black;
  border: 1px solid #469037a4;
  border-radius: 0px;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(21, 193, 73, 0.505), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-appearance: none;
  transition: background 0.3s, box-shadow 0.3s;
  padding: 15px;
    padding-right: 0px;
    color: #000000; /* Off-white text color */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    border: 0.5px solid #737373; /* Slight border */
    font-family: "Quicksand", sans-serif; /* Font family */
    letter-spacing: 0.2px; /* Letter spacing */
    line-height: 1.3; /* Line height */
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Stack children vertically */
    align-items: left; /* Center children horizontally */
    text-align: left; /* Center text */
    justify-content: space-between;
    width: auto; /* Adjust width as needed, or keep it auto */
    border: 0.5px solid #3c3c3c;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    transition: background 0.3s, box-shadow 0.3s;


}

.brain-card h3 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brain-card p {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.card-chips {
  display: flex;
  justify-content: center;
  gap: 10px;
  transition: opacity 0.3s ease;
  width: 100%;
}

.chip {
  padding: 5px 10px;
  background-color: transparent;
  border: none; /* Slight border */
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  border-radius: 15px; /* Rounded edges for chip */
  font-size: 0.8em; /* Smaller font size */
  cursor: default; /* Default cursor to indicate non-clickable */
}

.popup-wrapper {
  position: relative;
  display: inline-block;
}

.popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  width: 300px; /* Adjust based on your design */
  background-color: #2323234a;
  color: #fcfbf2; /* Light color for text */
  border-radius: 8px;
  border: 0.5px solid #3c3c3c; /* Light border */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow */
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  align-items: center;

}

.popup button{
  margin-bottom: 10px;
}

.popup input[type="text"], .popup button {
  width: calc(100% - 40px); /* Full width minus padding */
  padding: 15px 10px;
  margin-top: 10px;
  box-sizing: border-box;
  background-color: #2a2a2a60;
  border: 0.5px solid #3c3c3c;
  color: #fcfbf2; /* Light color for text */
  border-radius: 8px;
  letter-spacing: 0.2px;
  line-height: 1.3;
  backdrop-filter: blur(10px); /* Apply blur effect */

}

.popup input[type="text"] {
  margin-bottom: 20px; /* Space between inputs and button */
  background-color: #2a2a2a60;
  border: 0.5px solid #3c3c3c;
}

.popup input[type="text"]:focus {
  outline: none; /* Optional: Removes the default focus outline */
}

.popup button {
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #2a2a2a60;
  border: 0.5px solid #7d5aa0;
  color: #7d5aa0;

}

.popup button:hover {
  background-color: #191919; /* Darker on hover */
}


.modal-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.modal-input {
  margin: 10px 0;
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  border: 0.5px solid #3c3c3c;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  color: #fcfbf2;
}

.modal-input-large {
  margin: 10px 0;
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  border: 0.5px solid #3c3c3c;
  border-radius: 8px;
  width: auto !important;
  height: 100px !important;
  color: #fcfbf2;
}

.modal-button {
  background: linear-gradient(to bottom, #2d2d2d31 0%, #29292933 100%);
  border: 1px solid #000;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1), inset 0 10px 10px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-appearance: none;
  transition: background 0.3s, box-shadow 0.3s;
  margin-top: 20px;
}

.modal-button:active .modal-input:active #Modal {
  border: 1px solid #7d5aa0;
}


.modal-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

.modal-checkbox input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  outline: none;
}

.modal-checkbox input[type="checkbox"]:checked {
  background-color: #6a3c97;
  border: none;
}

.modal-checkbox input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: solid #232323a6;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.modal-checkbox label {
  display: flex;
  align-items: center;
  color: #fcfbf2;
  font-size: 16px;
}

.modal-checkbox .helper-text {
  margin-top: 5px;
  font-size: 12px;
  color: #ccc;
}

.brains-table {
  width: 100%;
  border-collapse: collapse;
  color: #fcfbf2;
}

.brains-table th, .brains-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.brains-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.brains-table td[contenteditable="true"] {
  background-color: #fff;
  cursor: text;
}

.modal-input-large {
  height: 30rem !important;
}

.css-2pyudb{ 
  max-height: 80vh;
  overflow: auto;
}

.text-button{
  background-color: transparent;
  border: none;
  color: #666666;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.additional-input-buttons{
  display: flex;
  flex-direction: column;
  justify-content: left !important;
  align-items: flex-start !important;
  gap: 10px;
  margin-top: 10px;
}