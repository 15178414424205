.burger-menu {
    position: relative;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.burger-button {
    padding: 10px 25px;
    background-color: transparent;
    color: #282828;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: monospace;
    /*box-shadow: 0 8px 16px rgba(0,0,0,0.2);*/
    border-radius: 8px;
    border: 2px solid #efeee5;
    margin-right: 20px;
    gap:10px;
}

.menu-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fcfbf2;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border: 1px solid #e4e3d6;
    border-radius: 4px;
    width: 100%;
    z-index: 1000;
    padding-top: 15px;
    padding-bottom: 15px;
}

.burger-menu .open-modal-button {
    margin-right: 0px;
}
.burger-menu .button {
    margin-right: 0px;
}