



/*V2---------------*/


/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.2px;
  line-height: 1.3;
  background-color: #040404; /* Soft off-white background */
  overflow: hidden;
}

.flex-row-container {
  display: flex;
  flex-direction: row; /* Aligns children side by side */
  height: 100vh; /* Adjust based on your needs */
  overflow: hidden; /* Optional, depending on your design */
}

/* Flex container for main layout */
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 100vh;
  min-height: 100vh;
  margin: 0px;
  margin-top: 0px;
  /*box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);  Inset shadow configuration */
  border-radius: 0px !important;
  border-top: 0.5px solid #404040;
  overflow: hidden; 
  border-radius: 0px 0px 0px 0px;
  width: 100%;
}

.nav-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: right; /* Adjusts the space between components */
  align-items: center; /* Aligns items vertically in the center */
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  background: transparent; /* Gradient from #fcfbf2 to transparent */
  transition: opacity 0.5s ease, max-height 0.5s ease;
  opacity: 1;
  visibility: visible;
  animation-fill-mode: forwards; /* Keep the container in the state of the last keyframe when the animation finishes */
  animation-duration: 0.5s; /* Animation duration */
  animation-timing-function: ease; /* Animation timing function for a smooth effect */

}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.nav-container.show {
  animation-name: fadeIn; /* Apply the fadeIn animation */
  
}

.nav-container.hide {
  animation-name: fadeOut; /* Apply the fadeOut animation */
  pointer-events: none; /* Prevent interaction with the container while it is animating out */
}



/* Simplified column styles */
.column {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 0px;
  width: 95%; /* Full width on smaller screens */
  max-width: 100%; /* Max width for larger screens */

  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  background-color: transparent; /* Pure white for content backgrounds */
  border-radius: 8px; /* Slight rounding of corners for a softer look */
  max-height: 100vh; /* Adjust based on your needs */
  overflow-y: hidden; /* Enables vertical scrolling */
  border-radius: 0px 0px 0px 0px !important;
}

.flex-column {
  flex: 1; /* Allows the column to expand and fill the available space */
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%; /* Full width on smaller screens */
  box-sizing: border-box;
  background-color: #fff; /* Pure white for content backgrounds */
  border-radius: 4px; /* Slight rounding of corners for a softer look */
  max-height: 96vh; /* Adjust based on your needs */
  margin: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .column {
    width: 100%;
    max-width: none;
    overflow-x: hidden;
  }
}

/* Component container */
.component-container {
  padding: 20px;
  margin: 0;
  background-color: #fff; /* Pure white for a clean look */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05); /* Very soft shadow for depth */
}

/* Editor container with minimal styling for focus on content */
.editor-container {
  padding: 20px;
  background-color: transparent;
  border: 1px solid #e0e0e0; /* Very light grey border */
}

/* Navigation and content styling */
.top-nav, .main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.top-nav {
  background-color: rgb(0, 0, 0); /* Semi-transparent background */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-shadow: 0px 8px 20px rgba(20, 214, 53, 0.521);
}

.main-content {
  flex-direction: column;
  gap: 20px;
}


.text-primary {
  color: #606060;
}

.btn {
  padding: 10px 20px;
  background-color: #f5f5f5; 
  color: #333;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: #e0e0e0; 
}

.notification-popup {
  display: flex; 
  justify-content: center; 
  align-items: center;
  gap: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #749e77; 
  color: #fcfbf2; 
  text-align: center;
  padding: 10px 0;
  border-radius: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 2000;
}

.icon {
   color: #fcfbf2;
}

.graph-toggle {
  padding: 10px 15px;
  background-color: transparent;
  border: none;
  color: #fcfbf2;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 5vh;
  border-radius: 8px;
}

.graph-toggle:hover {
  background-color: #191919;
}

.back-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #fcfbf2;
  height: 5vh;
}



