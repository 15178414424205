

.profile-button {
    padding: 10px 15px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
}

